<template>
  <div style="text-align: left;">
    <el-tag style="margin-left: 20px; margin-top: 50px;"
            v-for="status in statuses"
            :key="status.name"
            :type="status.type"
            closable
            @close="deleteStatus(status.name)">
      {{status.name}}
    </el-tag>
    <br />
    <el-popover placement="top"
                width="160"
                v-model="visible">
      <el-input v-model="name"
                clearable
                placeholder="Input status name"></el-input>
      <div style="text-align: right; margin-top: 10px;">
        <el-button size="mini"
                   type="text"
                   @click="visible = false, name = ''">Cancel</el-button>
        <el-button type="primary"
                   size="mini"
                   @click="addStatus">Confirm</el-button>
      </div>
      <el-button slot="reference"
                 style="margin-top: 50px; margin-left: 20px;"
                 type="primary"
                 size="small">Add Status</el-button>
    </el-popover>
  </div>
</template>
<script>
import http from '../utils/http'
export default {
  data() {
    return {
      visible: false,
      name: '',
      types: ['success', 'info', 'warning', 'danger', ''],
      statuses: [
        {
          name: 'New',
          type: 'success'
        }
      ]
    }
  },
  mounted() {
    this.getStatus()
  },
  methods: {
    addStatus() {
      http.post('/status/addStatus', { name: this.name }).then(res => {
        if (res.data.status === 1) {
          this.$message({
            message: 'status was added successfully!',
            type: 'success'
          })
          this.statuses.push({
            name: this.name,
            type: this.types[
              Math.floor(Math.random() * 10) > 5
                ? Math.floor(Math.random() * 10) - 5
                : Math.floor(Math.random() * 10)
            ]
          })
          this.name = ''
          this.visible = false
        } else {
          this.$message.error(res.data.message)
        }
      })
      this.tags.push({ name: this.status })
      ;(this.status = ''), (this.visible = false)
    },
    deleteStatus(name) {
      http.post('/status/deleteStatus', { name: name }).then(res => {
        if (res.data.status === 1) {
          this.$message({
            message: 'the status was deleted successfully!',
            type: 'success'
          })
          this.statuses.splice(this.statuses.indexOf(name), 1)
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    getStatus() {
      http.get('/status/').then(res => {
        if (res.data.status === 1) {
          this.statuses.splice(0, this.statuses.length)
          res.data.data.forEach(value => {
            let a =
              Math.floor(Math.random() * 10) > 5
                ? Math.floor(Math.random() * 10) - 5
                : Math.floor(Math.random() * 10)
            this.statuses.push({ name: value.name, type: this.types[a] })
          })
        } else {
          this.$message.error(res.data.message)
        }
      })
    }
  }
}
</script>
<style scoped>
</style>